import React from "react"
import styled from "styled-components"
import 'carbon-components/css/carbon-components.min.css';
import { Row, Col } from 'react-grid-system';
import { Container, Section } from "../global";
import Product1 from "../../images/product/commerce2x-onlinestore.jpg";
import Product2 from "../../images/product/commerce2x-b2b.png";
import Product3 from "../../images/product/commerce2x-marketplace.jpg";
import Product4 from "../../images/product/commerce2x-pos.jpg";

import Product5 from "../../images/product/commerce2x-pos-outlet.png";
import Product6 from "../../images/product/commerce2x-pos-centralized.png";
import Product7 from "../../images/product/commerce2x-pos-discounts.png";

import Product8 from "../../images/product/product-bigbarters-marketplace.png";
import Product9 from "../../images/product/commerce2x-multi-stores-inventory.png";

import Product10 from "../../images/product/commerce2x-Insights-report.png";
import Product11 from "../../images/product/product-bigbarters-ecommerce.png";
import Product12 from "../../images/product/commerce2x-rtl-support.png"
import Product13 from "../../images/product/commerce2x-pwa.png";

import Product17 from "../../images/product/commerce2x-cashu-payments.png";
import Product15 from "../../images/product/commerce2x-stripe-payments.png";
import Product16 from "../../images/product/commerce2x-mollie-payments.png";
import Product18 from "../../images/product/commerce2x-paypal-payments.png";
import Product19 from "../../images/product/commerce2x-braintree-payments.png";
import Product20 from "../../images/product/commerce2x-authorizenet-payments.png";
import Product21 from "../../images/product/commerce2x-weaccept-payments.png";


import Product22 from "../../images/product/commerce2x-ebay.png";
import Product23 from "../../images/product/commerce2x-aramex.png";
import Product24 from "../../images/product/commerce2x-fedex.png";
import Product25 from "../../images/product/commerce2x-ups.png";
import Product26 from "../../images/product/commerce2x-amazon.png";
import Product27 from "../../images/product/commerce2x-aliexpress.png";
import Product28 from "../../images/product/commerce2x-features.jpg"
import Product29 from "../../images/product/commerce2x-seller-price.jpg"

import Product30 from "../../images/product/commerce2x-access-control.jpg"
import Product31 from "../../images/product/commerce2x-dashboard.jpg"

import { ExpandableTile, Tile, TileAboveTheFoldContent,
  TileBelowTheFoldContent, Tabs, Tab, Button } from 'carbon-components-react';

const GetStartedPlatform = () => (
  <div>
  {/* <div style={{backgroundColor:'#121118', height:'5vh', width:'100%', marginTop:'100px', color:'#fff', textAlign:'center', paddingTop:'8px', fontWeight:'300'}}><p style={{fontSize:'18px'}}><Button>See Commerce2x Demo</Button></p></div> */}
    <GetStartedContainer style={{marginTop:'40px'}}> 

        <h1>Platform Overview</h1>
        <br/>
       <Tabs type="container">
        <Tab label="ONLINE STORE" style={{width:'200px'}}>
          <div className="some-content">
          <Subtitle>IMPACT YOUR CUSTOMERS</Subtitle>
          <GetStartedTitle>Modern online store to grow your products sales.</GetStartedTitle>
          <SectionSubTitle>With responsive themes and a comprehensive site manager, you can create a unique store — without code — in minutes. Order management, modern sales tools and powerful built-in features designed to take you from one stage of growth to the next. During the buyer’s journey, users move from one channel to another and it’s crucial to keep your brand experience consistent. Commerce2x online store makes this possible, with many additional benefits. </SectionSubTitle>
          
          <Row style={{
            paddingTop: '50px', width:'100%', paddingBottom:'50px'
          }}>

              <Col sm={12} lg={7}>
              <img src={Product1} style={{width:'100%'}}></img>
              </Col>
              <Col sm={12} lg={5}>

              <ExpandableTile style={{backgroundColor:'#2829D2', marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <div style={{ height: '280px', color:'#fff', margin:'25px' }}><h3>Commerce Storefront</h3><br/><br/>
                      <p style={{fontSize:'20px', lineHeight:'26px'}}>Enjoy the flexibility and extensibility that the Commerce2x ecosystem has to offer to the developers and small businesses. Give your shoppers a deeply engaging experience as soon as they land on your website without asking them to take a high-friction step of installing a native app.</p>
                      </div>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>
              
              </Col>
          </Row>

          <Row style={{
                paddingTop: '20px', width:'100%', paddingBottom:'20px'
              }}>

              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Multi-Store Inventory </h4>
                    <img src={Product9} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>
              <p style={{padding:'20px'}}>Connect your store with multiple inventory source to manage multi-channel inventories from a single website and easily track your product stock in a particular inventory.</p>
              </Col>
              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Insights</h4>
                    <img src={Product12} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>
              <p style={{padding:'20px'}}>Get detailed insights into your customers, orders and product sales to create an effective marketing campaign and track your progress. The dashboard provides detail analytics to the store owner.</p>
              </Col>
              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <h4 style={{ textAlign:'center'}}>Arabic Support</h4>
                    <img src={Product13} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>
              <p style={{padding:'20px'}}>Bringing you the very first RTL (Arabic) supported ecosystem framework for MENA eCommerce market with integrated logistics and payment methods.</p>
              </Col>
             
             </Row>

             <img src={Product30} style={{width:'100%'}}></img>
          </div>
        </Tab>
        <Tab  label="B2B COMMERCE">
          <div className="some-content">
          <Subtitle>B2B WHOLESALE COMMERCE</Subtitle>
          <GetStartedTitle>Commerce2x B2B Commerce offers buyers the seamless, self-service experience of online shopping with all the B2B functionality they demand.</GetStartedTitle>
          <SectionSubTitle>Enable a better B2B ecommerce experience by utilizing automated customer and pricing segmentation, punch out, purchase orders. Stock the shelves of your retail stores, enable online ordering of replacements parts, or meet the needs of your distribution or wholesale partners.</SectionSubTitle>

          <Row style={{
            paddingTop: '50px', width:'100%', paddingBottom:'50px'
          }}>

              <Col lg={5} sm={12}>
              <ExpandableTile style={{backgroundColor:'#2829D2', marginBottom:'30px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <div style={{ height: '280px', color:'#fff', margin:'25px' }}><h3>B2B Commerce Platform</h3><br/><br/>
                      <p style={{fontSize:'20px', lineHeight:'26px'}}>After adding products to shopping cart and selecting shipping methods, your buyers can add their payment method with the option of secure credit card payments. On your dashboard you can monitor account balance and payments history.</p>
                      </div>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>

              </Col>
              <Col lg={7} sm={12}>
              <img src={Product2} style={{width:'70%'}}></img>
              </Col>
             
          </Row>
          <img src={Product29} style={{width:'100%', marginBottom:'20px'}}></img>
          <img src={Product31} style={{width:'100%'}}></img>
          </div>
        </Tab>
        <Tab  label="B2C COMMERCE">
          <div className="some-content">
            
          <Subtitle>B2C COMMERCE</Subtitle>
          <GetStartedTitle>Marketplace B2C commerce platform. <br/>One Store, Multiple Sellers</GetStartedTitle>
          <SectionSubTitle>Commerce2x B2C Marketplace Platform enableS secure online transactions between buyers and sellers of various goods. Connect to the shoppers anytime, anywhere with our B2C commerce cloud.</SectionSubTitle>

          <Row style={{
            paddingTop: '50px', width:'100%', paddingBottom:'50px'
          }}>

              <Col sm={12} lg={5}>
              <ExpandableTile style={{backgroundColor:'#2829D2', marginBottom:'30px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <div style={{ height: '280px', color:'#fff', margin:'25px' }}><h3>B2C Commerce Platform</h3><br/><br/>
                      <p style={{fontSize:'20px', lineHeight:'26px'}}>Online marketplaces aren’t just for Amazon, EBay or AliBaba. Commerce2x makes it possible for any retailer or brand to accelerate eCommerce by offering more products & services from third parties with their own online marketplace.</p>
                      </div>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>

              </Col>
              <Col sm={12} lg={7}>
              <img src={Product3} ></img>
              </Col>
             
          </Row>
          <Row style={{
                paddingTop: '20px', width:'100%', paddingBottom:'20px'
              }}>

              <Col sm={12} lg={6}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Marketplace </h4>
                    <img src={Product8} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>

                  
              </ExpandableTile>
              <p style={{padding:'20px'}}>Convert your store into the marketplace where multiple vendors can sell their products. Give power to your vendors/suppliers to sell online and allow consumers different purchase options from multiple vendors to increase conversions on your website.</p>
             
              </Col>
              <Col sm={12} lg={6}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Product SEO</h4>
                    <img src={Product11} style={{width:'80%'}}></img>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>
              <p style={{padding:'20px'}}>Enrich your product with meta description, title, keywords and further enhance your product SEO to make sure that they appear on top of the search directly to your audience.</p>
             
              </Col>
             
             
             </Row>
            
             <img src={Product29} style={{width:'100%'}}></img>
            
            </div>
        </Tab>
        
        <Tab  label="POINT OF SALE">
          <div className="some-content">

          <Subtitle>POINT OF SALE</Subtitle>
          <GetStartedTitle>Commerce2x Point of Sale (POS)</GetStartedTitle>
          <SectionSubTitle>Convert your traditional eCommerce store into an all in one solution that enables selling your products in your retail stores for a true omni-channel experience. Commerce2x POS is designed to be user-friendly, with the purpose of making retailers life easier and also help them to make more effective business strategies. The retailer can easily track their change in inventory levels, day to day sales and can get their customer data which helps in recognizing the loyal customer and provide them with some extra discounts.</SectionSubTitle>

          <Row style={{
            paddingTop: '50px', width:'100%', paddingBottom:'50px'
          }}>

              <Col sm={12} lg={5}>
              <ExpandableTile style={{backgroundColor:'#2829D2', marginBottom:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <div style={{ height: '280px', color:'#fff', margin:'25px' }}><h3>Point of Sale (POS)</h3><br/><br/>
                      <p style={{fontSize:'20px', lineHeight:'26px'}}>Meet a modern, fast and easy to use POS system for unifying your in-store and online sales. Delight customers at every step and win back time to focus on what matters most to your business. </p>
                      </div>
                    </TileAboveTheFoldContent>
                  
              </ExpandableTile>

              </Col>
              <Col sm={12} lg={7}>
              <img src={Product4} style={{ marginTop:'20px', width:'100%'}}></img>
              </Col>
             
          </Row>

              <Row style={{
                paddingTop: '50px', width:'100%', paddingBottom:'50px'
              }}>

              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Multiple Outlets</h4>
                    <img src={Product5} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                
              </ExpandableTile>
              <p style={{padding:'20px'}}>Commerce2x Point of Sale system allows the admin to create multiple POS outlets in any geography. And once the outlet is created, admin can easily assign the inventory for each and every outlet separately.</p>
              </Col>
              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Centralized</h4>
                    <img src={Product6} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                 
              </ExpandableTile>
              <p style={{padding:'20px'}}>For streamlining the business operations you need to make every business activity centralized or one system based which will help in making more efficient business decisions. POS is an all-in-one solution through which you retailer can get all information about inventory, sales, and customers data at one place.</p>
              </Col>
              <Col sm={12} lg={4}>
              <ExpandableTile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <h4 style={{ textAlign:'center'}}>Loyalty Discount</h4>
                    <img src={Product7} style={{width:'100%'}}></img>
                    </TileAboveTheFoldContent>
                   
              </ExpandableTile>
              <p style={{padding:'20px'}}>The POS user can offer extra discounts to their repeated customers in order to increase sales. Loyalty discounts give extra benefit or discounted rates for customers who are loyal to the brand.</p>
              </Col>
             
             </Row>

          </div>
        </Tab>
        <Tab  label="MOBILE PWA">
        <div className="some-content">

<Subtitle>PROGRESSIVE WEB APPS</Subtitle>
<GetStartedTitle>Mobile-first eCommerce Platform Powering PWA</GetStartedTitle>
<SectionSubTitle>Progressive Web Apps are a new standard combining the best features offered by modern browsers with the benefits of native mobile applications, leading to a significant increase in revenue.</SectionSubTitle>

<Row style={{
  paddingTop: '50px', width:'100%', paddingBottom:'50px'
}}>

    <Col sm={12} lg={5}>
    <ExpandableTile style={{backgroundColor:'#2829D2', marginBottom:'20px'}}>
          <TileAboveTheFoldContent style={{margin:'20px'}}>
            <div style={{ height: '320px', color:'#fff', margin:'25px' }}><h3>Ultra-fast, cross-platform, and offline enabled commerce experiences.</h3><br/><br/>
            <p style={{fontSize:'20px', lineHeight:'26px', textAlign:'center'}}>Progressive Web Application uses web compatibilities and provides a native app experience to the user. With Commerce2x eCommerce PWA, Customer can view your website in offline mode so as the resulting customer's engagement rates will be increased. Laravel eCommerce PWA is reliable and responsive.</p>
            </div>
          </TileAboveTheFoldContent>
        
    </ExpandableTile>

    </Col>
    <Col sm={12} lg={7}>
    <img src={Product13} style={{width:'80%', marginTop:'0.po0px'}}></img>
    </Col>
   
</Row>


</div>

        </Tab>
        

        <Tab  label="SHIPPING/PAYMENTS">
        <div className="some-content">
          <Subtitle>SHIPPING & INTEGRATIONS</Subtitle>
          <GetStartedTitle>Commerce2x shipping integrations and apps</GetStartedTitle>
          <p style={{fontSize:'20px', lineHeight:'26px', textAlign:'center'}}>Extend the capability of your store by adding feature rich extensions and plugins from the marketplace to provide an immersive experience for your customers.</p>
          <Row style={{
                paddingTop: '50px', width:'100%', paddingBottom:'50px'
              }}>

              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Sell on Ebay Platform</h4>
                    <img src={Product22} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>Aramex Shipping</h4>
                    <img src={Product23} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>Fedex Shipping</h4>
                    <img src={Product24} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>

              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <h4 style={{ textAlign:'center'}}>UPS Shipping </h4>
                    <img src={Product25} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Sell On Amazon</h4>
                    <img src={Product26} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>AliExpress Dropshipping</h4>
                    <img src={Product27} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
             
              
             </Row>

             <Subtitle>PAYMENT GATEWAYS</Subtitle>
          <GetStartedTitle>Commerce2x supported payment gateway.</GetStartedTitle>
          <p style={{fontSize:'20px', lineHeight:'26px', textAlign:'center'}}>Payment gateways are important to every online store, enabling them to collect and manage customer payments. A comprehensive list of online international payment gateway providers that integrate with the Commerce2x platform</p>
          
             <Row style={{
                paddingTop: '50px', width:'100%', paddingBottom:'50px'
              }}>

              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>Stripe</h4>
                    <img src={Product15} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>Mollie</h4>
                    <img src={Product16} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>Paypal</h4>
                    <img src={Product18} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>

              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                      <h4 style={{ textAlign:'center'}}>Weaccept </h4>
                    <img src={Product21} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent style={{margin:'20px'}}>
                    <h4 style={{ textAlign:'center'}}>BrainTree</h4>
                    <img src={Product19} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
              <Col sm={2}>
              <Tile style={{ marginTop:'20px'}}>
                    <TileAboveTheFoldContent>
                    <h4 style={{ textAlign:'center'}}>Authorize.net</h4>
                    <img src={Product20} style={{width:'100%', marginBottom:'0px'}}></img>
                    </TileAboveTheFoldContent>
                  
              </Tile>
             
              </Col>
             
              
             </Row>

          
          </div>
        </Tab>
        <Tab  label="ALL FEATURES">
          <div className="some-content">
          <img src={Product28} style={{width:'100%'}}></img>

          </div>
        </Tab>
      </Tabs>

      

     

     
    </GetStartedContainer>
    </div>
)

export default GetStartedPlatform

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.white};
  
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding: 40px 0 20px;
`

const GetStartedTitle = styled.h1`
  margin: 0 auto 32px;
  text-align: center;
  font-weight:400;
`
const SectionSubTitle = styled.p`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  font-size:24px;
  margin: 0 auto 20px;
  line-height:30px;
  text-align: center;
`

const TryItButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #131217;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`