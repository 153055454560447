import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import 'carbon-components/css/carbon-components.min.css';

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Stack from "../components/sections/stack"
import StartNow from "../components/sections/startNow"
import Footer from "../components/sections/footer"
import GetStartedPlatform from "../components/sections/getstarted-platform"

const IndexPage = () => (
  <Layout>
    <SEO title="Commerce2x Platform" />
    <Navigation />
    
    <GetStartedPlatform />
    
    <Stack/>
    <StartNow/>
    <Footer />
  </Layout>
)

export default IndexPage
